<template>
<header :class="{white:white}">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="top-func" :class="{home:!white}">
					
					<div v-if="currentLang == 'ar' " class="lang-change-button" v-on:click="checngeLang('en')"><a >English</a></div>
					<div v-else  class="lang-change-button" v-on:click="checngeLang('ar')"><a >Arabic</a></div>

					<ul>
						<li><a href="#"><i class="icn icon-facebook"></i></a></li>
						<li><a href="#"><i class="icn icon-twitter"></i></a></li>
						<li><a href="#"><i class="icn icon-instagram"></i></a></li>
						<li><a href="#"><i class="icn icon-linkedin"></i></a></li>
					</ul>
					<div class="mail"><a href="mailto:info@rubikly.com">info@rubikly.com</a></div>
				</div>
			</div>
			<div class="col-12 col-md-12 col-lg-3">
				<div class="logo"><a href="#"></a></div>

				<div class="mobile-menu">
					<div class="hamburger hamburger--spring">
						<span class="hamburger-box">
							<span class="hamburger-inner">
							</span>
						</span>
					</div>
				</div>
			</div>
			<div class="col-md-9">
				<nav>
					<ul>
					    <router-link to="/"><li><a >Home</a></li></router-link>
                        <router-link to="/About"><li><a >About</a></li></router-link>
                        <router-link to="/Services"><li><a >Services</a></li></router-link>
                        <router-link to="/Portfolio"><li><a >Portfolio</a></li></router-link>
                        <router-link to="/faq"><li><a >F.A.Q.</a></li></router-link>
                        <li class="btn"><a>Free Quote</a></li>

					</ul>
				</nav>
			</div>
		</div>

		<div class="mobile-menu-toggle">
			<ul class="main">
			 <li class="item"><a> <router-link to="/home">Home</router-link> </a></li>
				 <li class="item"><a> <router-link to="/about">About</router-link> </a></li>
				 <li class="item"><a> <router-link to="/services">Services</router-link> </a></li>
				 <li class="item"><a> <router-link to="/Portfolio">Portfolio</router-link> </a></li>
				 <li class="item"><a> <router-link to="/faq">F.A.Q.</router-link> </a></li>
			</ul>
			<a class="quote" href="#">Free Quote</a>
		</div>
	</div>
</header>
</template>


<style >	


	.router-link-exact-active {
    font-weight: 700;
}
</style>

<script>

export default {



data() {
	return {
		
			currentLang:  localStorage.getItem('lang') || this.$store.state.currentlang ,
			  whereami : '',
			  white: false,

	}
},

  watch: {
    // call again the method if the route changes
    '$route': 'getlocation'
  },
methods:{
	  getlocation: function(){
		 let x = this;
		  x.whereami = x.$router.currentRoute;
		  console.log(x.whereami);
		  if(x.whereami.name != "home"){
			  x.white = true;
			  console.log("this is not home ");
		  }else {
			 x.white = false;
			console.log("this is  home ");
			

		  }
	  },

	checngeLang: function (langcode) {
		
		this.$lang.setLang(langcode)
		this.$store.state.currentlang = langcode;
		if(langcode == 'ar'){
		 localStorage.setItem('lang', 'ar');
		this.$store.state.ArActive = true;
		console.log('switched store to ar and true');
		}else{
		 localStorage.setItem('lang', 'en');
		console.log('switched store to en and false');
		this.$store.state.ArActive = false;

		}
		this.currentLang = this.$store.state.currentlang;
		
		

	}




  },


  created: function () {
this.$lang.setLang(this.currentLang);
this.getlocation();

},
}
</script>